/**
 * HTML5 Boilerplate
 */
a,
button {
  color: inherit;
}
// @see https://css-tricks.com/eliminate-jumps-in-horizontal-centering-by-forcing-a-scroll-bar

body {

  // Clearfix body

  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

body,
html {
  height: 100%;
  position: relative;
  width: 100%;
}

html {
  box-sizing: border-box;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  border: 0;
  display: block;
  font-size: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

body {
  font: 16px sans-serif;
  margin: 0;
}

button,
input,
select,
textarea {
  font: 99% sans-serif;
}

code,
kbd,
pre,
samp {
  font-family: monospace, sans-serif;
}

a:active,
a:hover {
  outline: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
}

ol {
  list-style-type: decimal;
}

nav li,
nav ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
}

small {
  font-size: 85%;
}

strong,
th {
  font-weight: bold;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre {
  padding: 15px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

textarea {
  overflow: auto;
}

q {
  font-style: italic;
}

.ie6 legend,
.ie7 legend {
  margin-left: -7px;
}

input[type='radio'],
input[type='checkbox'] {
  vertical-align: middle;
}

.ie7 input[type='checkbox'] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

input[type='button'],
input[type='submit'],
input[type='image'],
button,
label {
  cursor: pointer;
}

button,
input,
select,
textarea {
  margin: 0;
}

input:invalid,
textarea:invalid {
  // -moz-box-shadow: 0 0 5px red;
  // -webkit-box-shadow: 0 0 5px red;
  // border-radius: 1px;
  // box-shadow: 0 0 5px red;
}

.no-boxshadow input:invalid,
.no-boxshadow textarea:invalid {
  background-color: #f0dddd;
}

::-moz-selection {
  background: #0a246a;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #0a246a;
  color: #fff;
  text-shadow: none;
}

a:link {
  -webkit-tap-highlight-color: rgba(45, 54, 142, 0.1);
}

button {
  overflow: visible;
  width: auto;
}

.ie7 img {
  -ms-interpolation-mode: bicubic;
}

*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
/*ne marche pas sous ie7*/

img {
  display: block;
  height: auto;
  max-width: 100%;
}

iframe {
  display: block;
  max-width: 100%;
  width: 100%;
}

input[type='text'],
textarea,
input[type='submit'],
input[type='password'],
input[type='number'],
input[type='email'],
input[type='date'] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

select {
  //-moz-appearance: none;
  //-webkit-appearance: none;
  -webkit-border-radius: 0;
  //appearance: none;
  border: 0;
  cursor: pointer;
  text-indent: 0;
  text-overflow: '';
}

select::-ms-expand {
  background: #fff;
  border: 0;
}

a {
  text-decoration: none;
}

.clearfix:after,
.clearfix:before {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}
