.modalNav {
    bottom: 0;
    height: 100%;
    left: calc(-50vw + 50%);
    position: fixed;
    right: calc(-50vw + 50%);
    top: 0;
    visibility: hidden;
    z-index: 1020;

    &.open {
        opacity: 1;
        visibility: visible;
    }

    &__overlay {
        background-color: rgba(0, 0, 0, 0.6);
        bottom: 0;
        left: calc(-50vw + 50%);
        position: fixed;
        right: calc(-50vw + 50%);
        top: 0;
        z-index: 21;
    }

    &__close {
        background: none;
        border: 0;
        color: @color-icon-primary;
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        right: 10px;
        top: 10px;

        &:hover {
            color: @color-black;
        }
    }

    &__container {
        background-color: @color-background;
        box-sizing: border-box;
        height: 100%;
        margin: 0 auto;
        max-width: 800px;
        overflow-y: auto;
        padding: 30px;
        position: relative;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1022;
    }
}

@media screen and (min-width: 767px) {
    .modalNav {
        height: 100vh;

        &__container {
            height: auto;
        }
    }
}
