@fonts-path: '/assets/fonts/';
@lato-font: 'Lato', sans-serif;

.font-face(@font-name, @fonts-path, @font-weight, @font-style, @svg-id) {
  @font-face {
    font-family: @font-name;
    font-display: swap;
    font-style: @font-style;
    font-weight: @font-weight;
    src: url('@{fonts-path}.eot');
    src: url('@{fonts-path}.eot?#iefix') format('embedded-opentype')
      , url('@{fonts-path}.woff2') format('woff2')
      , url('@{fonts-path}.woff') format('woff')
      , url('@{fonts-path}.ttf') format('truetype')
      , url('@{fonts-path}.svg#@{svg-id}') format('svg');
  }
}

.font-face('lato', '@{fonts-path}lato/black/lato-black', 900, normal, 'latoblack');
.font-face('lato', '@{fonts-path}lato/bold/lato-bold', 700, normal, 'latobold');
.font-face('lato', '@{fonts-path}lato/light/lato-light', 300, normal, 'latolight');
.font-face('lato', '@{fonts-path}lato/regular/lato-regular', 400, normal, 'latoregular');

@sa-size : 3.75rem;
@dfp-size: 2.5rem;

@header-size-desktop: 3.75em;
@header-size-mobile: 2.75em;

@desktop-screen: 1024px;
@mobile-screen: 768px;

body {
  -ms-overflow-style: scrollbar;
  margin: 0px;

  &.smart-app-display {
    &.has-emergency {
      .header-container {
          top: @sa-size + @dfp-size;

          .body-wrapper #wrapper-nav {
            top: calc(@sa-size + @dfp-size + @header-size-mobile);

            @media (min-width: @mobile-screen) {
              top: calc(@header-size-desktop + @sa-size + @dfp-size);
            }
          }
      }

      .wrapper-banner {
        margin-top: @sa-size + @dfp-size;
      }

      .emergencyBanner {
        top: @sa-size;
      }

      #smart-app {
        z-index: 10000;
      }

      #toolbox {
        padding-top: calc(@sa-size + @dfp-size);
      }
    }

    .header-container {
      top: @sa-size;

      .body-wrapper #wrapper-nav {
        top: calc(@header-size-mobile + @sa-size);

        @media (min-width: @mobile-screen) {
          top: calc(@header-size-desktop + @sa-size);
        }
      }
    }

    .wrapper-banner {
      margin-top: @sa-size;
    }

    .app-banner {
      top: 0;
      z-index: 13;
    }
  }

  &.smart-app-hide {
    padding-top: 0px;

    .header-container {
      border-width: 0px;
      top: 0px;

      .body-wrapper #wrapper-nav {
        top: calc(@header-size-mobile);

        @media (min-width: @mobile-screen) {
          top: calc(@header-size-desktop);
        }
      }
    }
  }

  &.has-emergency {
    .header-container {
      top: @dfp-size;
    }

    .header-container .body-wrapper #wrapper-nav {
      top: calc(@header-size-mobile + @dfp-size);

      @media (min-width: @mobile-screen) {
        top: calc(@header-size-desktop + @dfp-size);
      }
    }
  }

  #smart-app {
    position: fixed;
    width: 100%;
  }

  .header-container {
    background-color: @color-background;
    border-bottom: 1px solid @color-grey-light;
    box-sizing: border-box;
    clear: both;
    font-size: 16px !important; // avoid override size header element
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 1002;

    @media (min-width: @mobile-screen) {
      height: @header-size-desktop;
    }

    & + div {
      padding-top: @header-size-mobile;

      @media screen and (min-width: @desktop-screen) {
        padding-top: @header-size-desktop;
      }
    }

    a {
      text-decoration: none;
    }

    .body-wrapper {
      display: flex;
      justify-content: space-between;

      /* Burger button */
      .burger-menu-btn-wrapper {
        display: flex;
        flex-basis: 30%;
        flex-grow: 1;
        height: 100%;

        .menu-trigger {
          align-items: center;
          background: transparent;
          border: 0px;
          color: @color-icon-primary;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          font-family: @lato-font;
          font-size: 1em;
          font-weight: 700;
          justify-content: space-around;
          line-height: inherit;
          padding: 0px;
          position: relative;
          text-transform: initial;
          width: 60px;
          z-index: 1002;

          @media (min-width: @mobile-screen) {
            font-size: 1em;
            height: @header-size-desktop;
            width: 4.7em;
          }

          &:hover {
            color: @color-icon-primary-hover;
            cursor: pointer;

            .burgerMenu {
              &:after,
              &:before {
                background-color: @color-icon-primary-hover;
              }
            }
          }

          &:focus {
            color: @color-link;
            fill: @color-link;
            outline: 2px solid @color-button-accent-focus;
            outline-offset: -6px;

            .burgerMenu {
              &:after,
              &:before {
                background-color: @color-icon-primary-focus !important;
              }
            }
          }

          .burgerMenu {
            display: block;
            margin: 0px auto;
            position: relative;
            transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
            width: 1.6em;

            @media (min-width: @mobile-screen) {
              width: 2.2em;
            }

            &:after,
            &:before {
              background-color: @color-icon-primary;
              border-radius: 1px;
              content: ' ';
              height: 2px;
              left: 0px;
              position: absolute;
              top: 11px;
              transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
              width: 1.6em;

              @media (min-width: @mobile-screen) {
                height: 2px;
                left: 0px;
                top: 0.95em;
                width: 2.2em;
              }
            }

            &:before {
              top: 5px;

              @media (min-width: @mobile-screen) {
                top: 0.5em;
              }
            }
          }

          .menu-letters {
            font-size: 0.625em;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;

            @media (min-width: @mobile-screen) {
              font-size: 0.75em;
              font-weight: 700;
            }
          }
        }
      }

      /* Nav panel */
      #wrapper-nav {
        -moz-transition: all 0.5s ease;
        -ms-transform: translate(-100%, 0px);
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -webkit-transform: translate(-100%, 0px);
        -webkit-transition: all 0.5s ease;
        background-color: @color-background;
        bottom: 0px;
        display: block !important;
        left: calc(-50vw + 50%);
        position: fixed;
        top: calc(@header-size-mobile);
        transform: translate(-100%, 0);
        transition: all 0.5s ease;
        visibility: hidden;
        width: 100%;
        z-index: 1002;

        @media (min-width: @mobile-screen) {
          top: @header-size-desktop;
          width: 50%;
        }

        @media screen and (min-width: @desktop-screen) {
          top: @header-size-desktop;
          width: 350px;
        }

        .menu {
          box-sizing: border-box;
          font-weight: bold;
          margin: 0;
          max-height: 100%;
          overflow: auto;
          padding: 0;
          width: 100%;

          li {
            color: @color-text-title;
            cursor: pointer;
            list-style: none;

            @media screen and (min-width: @desktop-screen) {
              width: 350px;
            }

            a,
            button {
              align-items: center;
              background: transparent;
              border: 0px;
              box-sizing: border-box;
              color: inherit;
              display: flex;
              font-family: @lato-font;
              font-size: 1em;
              font-weight: bold;
              height: 3em;
              line-height: 1.2;
              padding-left: 1em;
              position: relative;
              text-decoration: none;
              white-space: initial;
              width: 100%;
              word-break: initial;

              &:hover,
              &:focus {
                background: @color-background;
                color: @color-link;
                outline: 0px;
                text-decoration: underline;
                text-underline-position: under;

                span {
                  color: @color-link;
                }

                .sr-only {
                  text-decoration: none;
                }
              }

              span {
                color: @color-text-title;
                font-size: 1em;
              }
            }
          }

          .menu-item {
            button:hover {
              border: 0px;
              color: @color-link;
              cursor: pointer;
            }

            &.separator {
              background: transparent;
              border: 0px none;
              height: auto;
              margin-bottom: auto;
              margin-top: auto;
            }

            &--hidden {
              display: none;
            }

            &.open {
              &.separator {
                .menu-level-1 {
                  border-bottom: 0px;
                }
              }

              .menu-level-1 {
                color: @color-link;
                margin-bottom: 0px;

                .icon-square-arrow {
                  -ms-transform: rotate(317deg);
                  margin-top: initial;
                  transform: rotate(317deg);
                }
              }

              .menu-level-2 {
                display: block;
              }
            }

            .menu-level-1 {
              .icon {
                background-position: center;
                background-repeat: no-repeat;
                display: block;
                margin: 0px;
                min-width: 5rem;
              }

              .icon-square-arrow {
                -ms-transform: rotate(135deg);
                background: none;
                border-right: 1px solid @color-text-title;
                border-top: 1px solid @color-text-title;
                display: block;
                float: right;
                height: 11px;
                margin-top: -8px;
                padding: 0px;
                position: absolute;
                right: 25px;
                top: 50%;
                transform: rotate(135deg);
                transition: all 0.3s ease;
                width: 12px;
              }

              .icon-calendar {
                background-image: url('/components/header-b2b/assets/icons/calendar.svg');
                background-size: contain;
                height: 1.5em;
              }

              .icon-help {
                background-image: url('/components/header-b2b/assets/icons/help.svg');
                background-size: contain;
                height: 1.5em;
              }

              &.request,
              &.help,
              &.geoloc,
              &.currency {
                padding: 0px;
                padding-right: 17px;
              }
            }

            .currency i {
              background: none;
              font-size: 1.2em;
              font-style: normal;
            }

            .geoloc i {
              background-size: 1.5em;
              height: 1.25em;
            }

            &.separator {
              .menu-level-1 {
                border-bottom: 1px solid @color-grey-light;
              }
            }
          }

          .menu-level-2 {
            background: @color-background;
            display: none;
            z-index: 1002;

            ul {
              display: block;
              padding-left: 0px;

              li {
                a {
                  min-height: auto;
                  padding: 0.75em 0.75em 0.75em 2.5em;

                  &:hover {
                    background: @color-background;
                    color: @color-link;
                    text-decoration: underline;
                    text-underline-position: under;

                    .text-container {
                      background: rgba(255, 255, 255, 0.9);
                    }
                  }
                }

                img {
                  display: none;
                }

                .text-container {
                  min-height: 25px;
                  padding: 0px;
                  position: relative;
                }

                &:first-child a {
                  border-top: 1px solid @color-grey-light;
                }

                &:last-child a {
                  border-bottom: 1px solid @color-grey-light;
                }
              }
            }
          }
        }

        tools-others {
          @media screen and (min-width: @desktop-screen) {
            display: none;
          }
        }
      }

      /* logo */
      .logo-wrapper {
        box-sizing: border-box;
        display: flex;
        flex-grow: 1;
        height: @header-size-mobile;
        justify-content: center;
        padding: 0px;

        @media (min-width: @mobile-screen) {
          height: @header-size-desktop;
        }

        a {
          img {
            box-sizing: border-box;
            height: @header-size-mobile;
            max-width: 400px;
            padding: 0.5em;
            width: 100%;

            @media (min-width: @mobile-screen) {
              height: @header-size-desktop;
            }
          }

          &:focus {
            outline: 2px solid @color-button-accent-focus;
            outline-offset: -6px;
          }
        }
      }

      /* user connect */
      .connect-zone {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;

        .link-navigation__connect-icon {
          height: 28px;
          width: 28px;

          @media (min-width: @mobile-screen) {
            height: 38px;
            width: 38px;
          }
        }

        .link-navigation__connect-icon-connected {
          display: none;
        }

        .link-navigation__connect-icon-disconnected {
          display: inline-block;
        }
      }
    }

    .overlay {
      margin-left: 0px;
      transition: all 0.5s ease;

      @media (min-width: @mobile-screen) {
        top: @header-size-desktop !important;
      }
    }
  }

  #arianne {
    padding-top: @header-size-desktop;
  }
}

body.open {
  overflow-y: hidden;
  position: fixed;
  width: 100%;

  .header-container {
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);

    .burger-menu-btn-wrapper {
      .menu-trigger {
        .burgerMenu {
          &:after,
          &:before {
            background-color: @color-icon-primary;
            top: 7px;
            width: 1.5em;

            @media (min-width: @mobile-screen) {
              top: 10px;
              width: 2em;
            }
          }

          &:after {
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }

          &:before {
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          @media (min-width: @mobile-screen) {
            width: 2em;
          }
        }
      }
    }

    #wrapper-nav {
      -ms-transform: translate(0%, 0px) !important;
      -webkit-transform: translate(0%, 0px) !important;
      transform: translate(0%, 0px) !important;
      visibility: visible;
    }

    .overlay {
      background-color: rgba(35, 35, 35, 0.85);
      height: 100vh;
      position: absolute;
      top: @header-size-mobile;
      width: 100%;
      z-index: 1001;
    }
  }
}

.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
