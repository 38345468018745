@import '../partials/_reset';
@import '../partials/_variables';
@import '../partials/_typography';
@import '../partials/_mixins';
@import '../partials/_breadcrumb';

html {
  color: @textColor;

  body {
    font-family: @font-page;

    &.has-emergency {
      .breadcrumb-container {
        margin: 0.75rem auto;
      }
    }
  }
}

.body-container {
  section {
    margin: 0 auto;
    max-width: 1400px;
  }

  .link {
    color: @linkColor;
    text-decoration-line: underline;
  }

  .banner-container {
    padding: 1.25rem 1.25rem 0 1.25rem;
    text-align: center;

    @media (min-width: @mobile-medium) {
      padding: 1.875rem 1.25rem;
    }

    &__title {
      color: @titleColor;
      font-family: @font-heading;
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      overflow-wrap: break-word;
      .font(22, 30);
    }

    &__paragraph {
      color: @textColor;
      font-family: @font-banner;
      font-style: italic;
      font-weight: normal;
      text-align: center;
      .font(15, 15);

      &-small {
        color: @textColor;
        font-style: normal;
        font-weight: normal;
        .font(10, 15);
      }
    }
  }

  .btn-breadcrumb {
    background: none;
    border: 0;
    padding: 0;
    text-decoration: none;
  }
}

.roundButton {
  background: @buttonBgColor;
  border-radius: 60px;
  color: white;
  cursor: pointer;

  &:hover {
    background: @buttonBgHoverColor;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
