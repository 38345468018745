/**
 * Typography partial styles
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: @font-family-heading;
  line-height: @line-height-heading;
  margin-top: @gutter-medium;
}

h1 {
  font-size: @font-size-h1;
}

h2 {
  font-size: @font-size-h2;
}

h3 {
  font-size: @font-size-h3;
}

h4 {
  font-size: @font-size-h4;
}

h5 {
  font-size: @font-size-h5;
}

h6 {
  font-size: @font-size-h6;
}

html {
  color: @color-dark;
  font-family: @font-family-page;
  font-weight: 400;
  line-height: @line-height-page;
}

p {
  margin-top: @gutter-medium;
}

.error {
  color: @color-error;
}

input[type='text'] {
  box-sizing: border-box;
}
