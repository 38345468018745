@import '../partials/_variables';

.breadcrumb-container {
  color: @textColor;
  display: none;
  font-family: @font-page;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.25rem;
  margin: 0 auto;
  padding: 0 1.25rem 1.25rem;
  position: relative;
  top: 1.563rem;

  @media (min-width: @mobile-medium) {
    display: block;
  }

  &__link {
    color: @linkColor;
    text-decoration-line: underline;

    &.btn-breadcrumb {
      text-decoration: none;
    }
  }

  &__item {
    &:not(:first-child) {
      &::before {
        border: solid @textColor;
        border-width: 0 1px 1px 0;
        content: "";
        cursor: text;
        display: inline-block;
        margin: 0 10px 1px 10px;
        padding: 3px;
        transform: rotate(-45deg);
      }
    }
  }
}
