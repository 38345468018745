/**
 * HTML5 Boilerplate
 */
a,
button {
  color: inherit;
}
body::after {
  clear: both;
  content: '';
  display: table;
}
body,
html {
  height: 100%;
  position: relative;
  width: 100%;
}
html {
  box-sizing: border-box;
}
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  border: 0;
  display: block;
  font-size: 0;
  height: 0;
  margin: 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
body {
  font: 16px sans-serif;
  margin: 0;
}
button,
input,
select,
textarea {
  font: 99% sans-serif;
}
code,
kbd,
pre,
samp {
  font-family: monospace, sans-serif;
}
a:active,
a:hover {
  outline: none;
}
ol,
ul {
  list-style: none;
  margin: 0;
}
ol {
  list-style-type: decimal;
}
nav li,
nav ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
}
small {
  font-size: 85%;
}
strong,
th {
  font-weight: bold;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
pre {
  padding: 15px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
textarea {
  overflow: auto;
}
q {
  font-style: italic;
}
.ie6 legend,
.ie7 legend {
  margin-left: -7px;
}
input[type='radio'],
input[type='checkbox'] {
  vertical-align: middle;
}
.ie7 input[type='checkbox'] {
  vertical-align: baseline;
}
.ie6 input {
  vertical-align: text-bottom;
}
input[type='button'],
input[type='submit'],
input[type='image'],
button,
label {
  cursor: pointer;
}
button,
input,
select,
textarea {
  margin: 0;
}
.no-boxshadow input:invalid,
.no-boxshadow textarea:invalid {
  background-color: #f0dddd;
}
::selection {
  background: #0a246a;
  color: #fff;
  text-shadow: none;
}
a:link {
  -webkit-tap-highlight-color: rgba(45, 54, 142, 0.1);
}
button {
  overflow: visible;
  width: auto;
}
.ie7 img {
  -ms-interpolation-mode: bicubic;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
/*ne marche pas sous ie7*/
img {
  display: block;
  height: auto;
  max-width: 100%;
}
iframe {
  display: block;
  max-width: 100%;
  width: 100%;
}
input[type='text'],
textarea,
input[type='submit'],
input[type='password'],
input[type='number'],
input[type='email'],
input[type='date'] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
select {
  -webkit-border-radius: 0;
  border: 0;
  cursor: pointer;
  text-indent: 0;
  text-overflow: '';
}
select::-ms-expand {
  background: #fff;
  border: 0;
}
a {
  text-decoration: none;
}
.clearfix:after,
.clearfix:before {
  content: '';
  display: table;
}
.clearfix:after {
  clear: both;
}
/* -- VARIABLES -- */
/* backgrounds */
/* textes */
/* b2b colors common*/
/* image path our-event-types*/
/** for typography file */
/* Devices Breakpoints normals*/
/** search-bar Breakpoint  */
/** Others Breakpoints  */
/* -- "GRID" -- */
/**
 * Typography partial styles
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Arial';
  line-height: 1.25;
  margin-top: 0.625rem;
}
h1 {
  font-size: 1.719rem;
}
h2 {
  font-size: 1.641rem;
}
h3 {
  font-size: 0.938rem;
}
h4 {
  font-size: 0.859rem;
}
h5 {
  font-size: 0.781rem;
}
h6 {
  font-size: 0.703rem;
}
html {
  color: #1a1e29;
  font-family: 'Arial';
  font-weight: 400;
  line-height: 1.125;
}
p {
  margin-top: 0.625rem;
}
.error {
  color: #c70000;
}
input[type='text'] {
  box-sizing: border-box;
}
.breadcrumb-container {
  color: #3e3d48;
  display: none;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.25rem;
  margin: 0 auto;
  padding: 0 1.25rem 1.25rem;
  position: relative;
  top: 1.563rem;
}
@media (min-width: 768px) {
  .breadcrumb-container {
    display: block;
  }
}
.breadcrumb-container__link {
  color: #1264a3;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.breadcrumb-container__link.btn-breadcrumb {
  text-decoration: none;
}
.breadcrumb-container__item:not(:first-child)::before {
  border: solid #3e3d48;
  border-width: 0 1px 1px 0;
  content: "";
  cursor: text;
  display: inline-block;
  margin: 0 10px 1px 10px;
  padding: 3px;
  transform: rotate(-45deg);
}
html {
  color: #3e3d48;
}
html body {
  font-family: 'Roboto', Arial, sans-serif;
}
html body.has-emergency .breadcrumb-container {
  margin: 0.75rem auto;
}
.body-container section {
  margin: 0 auto;
  max-width: 1400px;
}
.body-container .link {
  color: #1264a3;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.body-container .banner-container {
  padding: 1.25rem 1.25rem 0 1.25rem;
  text-align: center;
}
@media (min-width: 768px) {
  .body-container .banner-container {
    padding: 1.875rem 1.25rem;
  }
}
.body-container .banner-container__title {
  color: #252339;
  font-family: 'Montserrat', Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  overflow-wrap: break-word;
  font-size: 2.2rem;
  line-height: 3rem;
}
.body-container .banner-container__paragraph {
  color: #3e3d48;
  font-family: 'Unna';
  font-style: italic;
  font-weight: normal;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.body-container .banner-container__paragraph-small {
  color: #3e3d48;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}
.body-container .btn-breadcrumb {
  background: none;
  border: 0;
  padding: 0;
  text-decoration: none;
}
.roundButton {
  background: #1E1852;
  border-radius: 60px;
  color: white;
  cursor: pointer;
}
.roundButton:hover {
  background: #38326c;
}
.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
#ah-header-wrapper {
  display: none;
}
#mount-ah-navigation {
  min-height: 4.5rem;
}
@media (min-width: 1024px) {
  #loginnav {
    display: none;
  }
}
/* Colors */
/* Backgrounds */
/* Icons */
/* Links */
/* Buttons */
/* Text */
@font-face {
  font-family: 'lato';
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/lato/black/lato-black.eot');
  src: url('/assets/fonts/lato/black/lato-black.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/lato/black/lato-black.woff2') format('woff2'), url('/assets/fonts/lato/black/lato-black.woff') format('woff'), url('/assets/fonts/lato/black/lato-black.ttf') format('truetype'), url('/assets/fonts/lato/black/lato-black.svg#latoblack') format('svg');
}
@font-face {
  font-family: 'lato';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/lato/bold/lato-bold.eot');
  src: url('/assets/fonts/lato/bold/lato-bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/lato/bold/lato-bold.woff2') format('woff2'), url('/assets/fonts/lato/bold/lato-bold.woff') format('woff'), url('/assets/fonts/lato/bold/lato-bold.ttf') format('truetype'), url('/assets/fonts/lato/bold/lato-bold.svg#latobold') format('svg');
}
@font-face {
  font-family: 'lato';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/lato/light/lato-light.eot');
  src: url('/assets/fonts/lato/light/lato-light.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/lato/light/lato-light.woff2') format('woff2'), url('/assets/fonts/lato/light/lato-light.woff') format('woff'), url('/assets/fonts/lato/light/lato-light.ttf') format('truetype'), url('/assets/fonts/lato/light/lato-light.svg#latolight') format('svg');
}
@font-face {
  font-family: 'lato';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/lato/regular/lato-regular.eot');
  src: url('/assets/fonts/lato/regular/lato-regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/lato/regular/lato-regular.woff2') format('woff2'), url('/assets/fonts/lato/regular/lato-regular.woff') format('woff'), url('/assets/fonts/lato/regular/lato-regular.ttf') format('truetype'), url('/assets/fonts/lato/regular/lato-regular.svg#latoregular') format('svg');
}
body {
  -ms-overflow-style: scrollbar;
  margin: 0px;
}
body.smart-app-display.has-emergency .header-container {
  top: 3.75rem + 2.5rem;
}
body.smart-app-display.has-emergency .header-container .body-wrapper #wrapper-nav {
  top: calc(3.75rem + 2.5rem + 2.75em);
}
@media (min-width: 768px) {
  body.smart-app-display.has-emergency .header-container .body-wrapper #wrapper-nav {
    top: calc(3.75em + 3.75rem + 2.5rem);
  }
}
body.smart-app-display.has-emergency .wrapper-banner {
  margin-top: 3.75rem + 2.5rem;
}
body.smart-app-display.has-emergency .emergencyBanner {
  top: 3.75rem;
}
body.smart-app-display.has-emergency #smart-app {
  z-index: 10000;
}
body.smart-app-display.has-emergency #toolbox {
  padding-top: calc(3.75rem + 2.5rem);
}
body.smart-app-display .header-container {
  top: 3.75rem;
}
body.smart-app-display .header-container .body-wrapper #wrapper-nav {
  top: calc(2.75em + 3.75rem);
}
@media (min-width: 768px) {
  body.smart-app-display .header-container .body-wrapper #wrapper-nav {
    top: calc(3.75em + 3.75rem);
  }
}
body.smart-app-display .wrapper-banner {
  margin-top: 3.75rem;
}
body.smart-app-display .app-banner {
  top: 0;
  z-index: 13;
}
body.smart-app-hide {
  padding-top: 0px;
}
body.smart-app-hide .header-container {
  border-width: 0px;
  top: 0px;
}
body.smart-app-hide .header-container .body-wrapper #wrapper-nav {
  top: calc(2.75em);
}
@media (min-width: 768px) {
  body.smart-app-hide .header-container .body-wrapper #wrapper-nav {
    top: calc(3.75em);
  }
}
body.has-emergency .header-container {
  top: 2.5rem;
}
body.has-emergency .header-container .body-wrapper #wrapper-nav {
  top: calc(2.75em + 2.5rem);
}
@media (min-width: 768px) {
  body.has-emergency .header-container .body-wrapper #wrapper-nav {
    top: calc(3.75em + 2.5rem);
  }
}
body #smart-app {
  position: fixed;
  width: 100%;
}
body .header-container {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  clear: both;
  font-size: 16px !important;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1002;
}
@media (min-width: 768px) {
  body .header-container {
    height: 3.75em;
  }
}
body .header-container + div {
  padding-top: 2.75em;
}
@media screen and (min-width: 1024px) {
  body .header-container + div {
    padding-top: 3.75em;
  }
}
body .header-container a {
  text-decoration: none;
}
body .header-container .body-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  /* Burger button */
  /* Nav panel */
  /* logo */
  /* user connect */
}
body .header-container .body-wrapper .burger-menu-btn-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  height: 100%;
}
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger {
  -ms-flex-align: center;
      align-items: center;
  background: transparent;
  border: 0px;
  color: #252339;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  font-weight: 700;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  line-height: inherit;
  padding: 0px;
  position: relative;
  text-transform: initial;
  width: 60px;
  z-index: 1002;
}
@media (min-width: 768px) {
  body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger {
    font-size: 1em;
    height: 3.75em;
    width: 4.7em;
  }
}
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger:hover {
  color: #068484;
  cursor: pointer;
}
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger:hover .burgerMenu:after,
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger:hover .burgerMenu:before {
  background-color: #068484;
}
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger:focus {
  color: #1264a3;
  fill: #1264a3;
  outline: 2px solid #3470df;
  outline-offset: -6px;
}
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger:focus .burgerMenu:after,
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger:focus .burgerMenu:before {
  background-color: #3470df !important;
}
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .burgerMenu {
  display: block;
  margin: 0px auto;
  position: relative;
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  width: 1.6em;
}
@media (min-width: 768px) {
  body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .burgerMenu {
    width: 2.2em;
  }
}
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .burgerMenu:after,
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .burgerMenu:before {
  background-color: #252339;
  border-radius: 1px;
  content: ' ';
  height: 2px;
  left: 0px;
  position: absolute;
  top: 11px;
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  width: 1.6em;
}
@media (min-width: 768px) {
  body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .burgerMenu:after,
  body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .burgerMenu:before {
    height: 2px;
    left: 0px;
    top: 0.95em;
    width: 2.2em;
  }
}
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .burgerMenu:before {
  top: 5px;
}
@media (min-width: 768px) {
  body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .burgerMenu:before {
    top: 0.5em;
  }
}
body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .menu-letters {
  font-size: 0.625em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  body .header-container .body-wrapper .burger-menu-btn-wrapper .menu-trigger .menu-letters {
    font-size: 0.75em;
    font-weight: 700;
  }
}
body .header-container .body-wrapper #wrapper-nav {
  -moz-transition: all 0.5s ease;
  -ms-transform: translate(-100%, 0px);
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transform: translate(-100%, 0px);
  -webkit-transition: all 0.5s ease;
  background-color: #fff;
  bottom: 0px;
  display: block !important;
  left: calc(-50vw + 50%);
  position: fixed;
  top: calc(2.75em);
  transform: translate(-100%, 0);
  transition: all 0.5s ease;
  visibility: hidden;
  width: 100%;
  z-index: 1002;
}
@media (min-width: 768px) {
  body .header-container .body-wrapper #wrapper-nav {
    top: 3.75em;
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  body .header-container .body-wrapper #wrapper-nav {
    top: 3.75em;
    width: 350px;
  }
}
body .header-container .body-wrapper #wrapper-nav .menu {
  box-sizing: border-box;
  font-weight: bold;
  margin: 0;
  max-height: 100%;
  overflow: auto;
  padding: 0;
  width: 100%;
}
body .header-container .body-wrapper #wrapper-nav .menu li {
  color: #252339;
  cursor: pointer;
  list-style: none;
}
@media screen and (min-width: 1024px) {
  body .header-container .body-wrapper #wrapper-nav .menu li {
    width: 350px;
  }
}
body .header-container .body-wrapper #wrapper-nav .menu li a,
body .header-container .body-wrapper #wrapper-nav .menu li button {
  -ms-flex-align: center;
      align-items: center;
  background: transparent;
  border: 0px;
  box-sizing: border-box;
  color: inherit;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  font-weight: bold;
  height: 3em;
  line-height: 1.2;
  padding-left: 1em;
  position: relative;
  text-decoration: none;
  white-space: initial;
  width: 100%;
  word-break: initial;
}
body .header-container .body-wrapper #wrapper-nav .menu li a:hover,
body .header-container .body-wrapper #wrapper-nav .menu li button:hover,
body .header-container .body-wrapper #wrapper-nav .menu li a:focus,
body .header-container .body-wrapper #wrapper-nav .menu li button:focus {
  background: #fff;
  color: #1264a3;
  outline: 0px;
  text-decoration: underline;
  text-underline-position: under;
}
body .header-container .body-wrapper #wrapper-nav .menu li a:hover span,
body .header-container .body-wrapper #wrapper-nav .menu li button:hover span,
body .header-container .body-wrapper #wrapper-nav .menu li a:focus span,
body .header-container .body-wrapper #wrapper-nav .menu li button:focus span {
  color: #1264a3;
}
body .header-container .body-wrapper #wrapper-nav .menu li a:hover .sr-only,
body .header-container .body-wrapper #wrapper-nav .menu li button:hover .sr-only,
body .header-container .body-wrapper #wrapper-nav .menu li a:focus .sr-only,
body .header-container .body-wrapper #wrapper-nav .menu li button:focus .sr-only {
  text-decoration: none;
}
body .header-container .body-wrapper #wrapper-nav .menu li a span,
body .header-container .body-wrapper #wrapper-nav .menu li button span {
  color: #252339;
  font-size: 1em;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item button:hover {
  border: 0px;
  color: #1264a3;
  cursor: pointer;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item.separator {
  background: transparent;
  border: 0px none;
  height: auto;
  margin-bottom: auto;
  margin-top: auto;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item--hidden {
  display: none;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item.open.separator .menu-level-1 {
  border-bottom: 0px;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item.open .menu-level-1 {
  color: #1264a3;
  margin-bottom: 0px;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item.open .menu-level-1 .icon-square-arrow {
  -ms-transform: rotate(317deg);
  margin-top: initial;
  transform: rotate(317deg);
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item.open .menu-level-2 {
  display: block;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .menu-level-1 .icon {
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  margin: 0px;
  min-width: 5rem;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .menu-level-1 .icon-square-arrow {
  -ms-transform: rotate(135deg);
  background: none;
  border-right: 1px solid #252339;
  border-top: 1px solid #252339;
  display: block;
  float: right;
  height: 11px;
  margin-top: -8px;
  padding: 0px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: rotate(135deg);
  transition: all 0.3s ease;
  width: 12px;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .menu-level-1 .icon-calendar {
  background-image: url('/components/header-b2b/assets/icons/calendar.svg');
  background-size: contain;
  height: 1.5em;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .menu-level-1 .icon-help {
  background-image: url('/components/header-b2b/assets/icons/help.svg');
  background-size: contain;
  height: 1.5em;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .menu-level-1.request,
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .menu-level-1.help,
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .menu-level-1.geoloc,
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .menu-level-1.currency {
  padding: 0px;
  padding-right: 17px;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .currency i {
  background: none;
  font-size: 1.2em;
  font-style: normal;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item .geoloc i {
  background-size: 1.5em;
  height: 1.25em;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-item.separator .menu-level-1 {
  border-bottom: 1px solid #ccc;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-level-2 {
  background: #fff;
  display: none;
  z-index: 1002;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-level-2 ul {
  display: block;
  padding-left: 0px;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-level-2 ul li a {
  min-height: auto;
  padding: 0.75em 0.75em 0.75em 2.5em;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-level-2 ul li a:hover {
  background: #fff;
  color: #1264a3;
  text-decoration: underline;
  text-underline-position: under;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-level-2 ul li a:hover .text-container {
  background: rgba(255, 255, 255, 0.9);
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-level-2 ul li img {
  display: none;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-level-2 ul li .text-container {
  min-height: 25px;
  padding: 0px;
  position: relative;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-level-2 ul li:first-child a {
  border-top: 1px solid #ccc;
}
body .header-container .body-wrapper #wrapper-nav .menu .menu-level-2 ul li:last-child a {
  border-bottom: 1px solid #ccc;
}
@media screen and (min-width: 1024px) {
  body .header-container .body-wrapper #wrapper-nav tools-others {
    display: none;
  }
}
body .header-container .body-wrapper .logo-wrapper {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1;
  height: 2.75em;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0px;
}
@media (min-width: 768px) {
  body .header-container .body-wrapper .logo-wrapper {
    height: 3.75em;
  }
}
body .header-container .body-wrapper .logo-wrapper a img {
  box-sizing: border-box;
  height: 2.75em;
  max-width: 400px;
  padding: 0.5em;
  width: 100%;
}
@media (min-width: 768px) {
  body .header-container .body-wrapper .logo-wrapper a img {
    height: 3.75em;
  }
}
body .header-container .body-wrapper .logo-wrapper a:focus {
  outline: 2px solid #3470df;
  outline-offset: -6px;
}
body .header-container .body-wrapper .connect-zone {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin-right: 10px;
}
body .header-container .body-wrapper .connect-zone .link-navigation__connect-icon {
  height: 28px;
  width: 28px;
}
@media (min-width: 768px) {
  body .header-container .body-wrapper .connect-zone .link-navigation__connect-icon {
    height: 38px;
    width: 38px;
  }
}
body .header-container .body-wrapper .connect-zone .link-navigation__connect-icon-connected {
  display: none;
}
body .header-container .body-wrapper .connect-zone .link-navigation__connect-icon-disconnected {
  display: inline-block;
}
body .header-container .overlay {
  margin-left: 0px;
  transition: all 0.5s ease;
}
@media (min-width: 768px) {
  body .header-container .overlay {
    top: 3.75em !important;
  }
}
body #arianne {
  padding-top: 3.75em;
}
body.open {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
}
body.open .header-container {
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
}
body.open .header-container .burger-menu-btn-wrapper .menu-trigger .burgerMenu:after,
body.open .header-container .burger-menu-btn-wrapper .menu-trigger .burgerMenu:before {
  background-color: #252339;
  top: 7px;
  width: 1.5em;
}
@media (min-width: 768px) {
  body.open .header-container .burger-menu-btn-wrapper .menu-trigger .burgerMenu:after,
  body.open .header-container .burger-menu-btn-wrapper .menu-trigger .burgerMenu:before {
    top: 10px;
    width: 2em;
  }
}
body.open .header-container .burger-menu-btn-wrapper .menu-trigger .burgerMenu:after {
  transform: rotate(-45deg);
}
body.open .header-container .burger-menu-btn-wrapper .menu-trigger .burgerMenu:before {
  transform: rotate(45deg);
}
@media (min-width: 768px) {
  body.open .header-container .burger-menu-btn-wrapper .menu-trigger .burgerMenu {
    width: 2em;
  }
}
body.open .header-container #wrapper-nav {
  transform: translate(0%, 0px) !important;
  visibility: visible;
}
body.open .header-container .overlay {
  background-color: rgba(35, 35, 35, 0.85);
  height: 100vh;
  position: absolute;
  top: 2.75em;
  width: 100%;
  z-index: 1001;
}
.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.modalNav {
  bottom: 0;
  height: 100%;
  left: calc(-50vw + 50%);
  position: fixed;
  right: calc(-50vw + 50%);
  top: 0;
  visibility: hidden;
  z-index: 1020;
}
.modalNav.open {
  opacity: 1;
  visibility: visible;
}
.modalNav__overlay {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: calc(-50vw + 50%);
  position: fixed;
  right: calc(-50vw + 50%);
  top: 0;
  z-index: 21;
}
.modalNav__close {
  background: none;
  border: 0;
  color: #252339;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 10px;
}
.modalNav__close:hover {
  color: #000;
}
.modalNav__container {
  background-color: #fff;
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  max-width: 800px;
  overflow-y: auto;
  padding: 30px;
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1022;
}
@media screen and (min-width: 767px) {
  .modalNav {
    height: 100vh;
  }
  .modalNav__container {
    height: auto;
  }
}
/* Devices Breakpoints normals*/
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.brands-collection {
  color: #fff;
  overflow: auto;
  width: 100%;
}
.brands-collection .hidden {
  display: none;
}
.brands-collection__wrapper {
  margin: 0 auto;
  max-width: 1312px;
}
.brands-collection__hr {
  border: 0;
  border-top: 1px solid #fff;
  clear: both;
  margin: 0;
  opacity: 0.15;
}
.brands-collection__hr:nth-child(1) {
  opacity: 0.6;
}
.brands-collection__hr:last-of-type {
  display: none;
}
.brands-collection__hr--main {
  margin-top: 86px;
  opacity: 0.6;
}
@media (min-width: 768px) {
  .brands-collection__hr--main {
    margin-top: 106px;
  }
}
.brands-collection__svg {
  background-color: #050033;
  display: block;
  fill: #fff;
  height: 70px;
  margin: -30px auto 45px;
  padding: 0 30px;
  position: relative;
  max-width: 250px;
}
.brands-collection__dl {
  overflow: auto;
  margin: 0;
}
.brands-collection__dt {
  font-style: italic;
  padding: 16px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  gap: 8px;
}
@media (min-width: 768px) {
  .brands-collection__dt {
    display: block;
    float: left;
    padding: 14px 0 0;
  }
}
.brands-collection__dd {
  margin: 0;
  padding: 6px 5px;
}
@media (min-width: 768px) {
  .brands-collection__dd {
    float: right;
    width: calc((100% + 24px) / 8 * 6 - 24px);
  }
}
@media (min-width: 1024px) {
  .brands-collection__dd {
    width: calc((100% + 24px) / 12 * 10 - 24px);
  }
}
.brands-collection__title {
  font-family: 'Unna', Georgia, serif;
  font-size: 24px;
}
.brands-collection__number {
  color: #8d88b4;
  font-size: 14px;
  font-style: normal;
}
@media (min-width: 768px) {
  .brands-collection__number {
    display: none;
  }
}
.brands-collection__list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.brands-collection__elements {
  -ms-flex-preferred-size: calc((100% / 3) - 1px);
      flex-basis: calc((100% / 3) - 1px);
  list-style: none;
  min-width: 5.7rem;
}
.brands-collection__elements:nth-child(n + 4) {
  display: none;
}
@media (min-width: 768px) {
  .brands-collection__elements {
    -ms-flex-preferred-size: calc((100% / 6) - 1px);
        flex-basis: calc((100% / 6) - 1px);
    min-width: 5.3rem;
  }
  .brands-collection__elements:nth-child(n + 4) {
    display: block;
  }
}
@media (min-width: 1024px) {
  .brands-collection__elements {
    -ms-flex-preferred-size: calc(100% / 10);
        flex-basis: calc(100% / 10);
    min-width: 4.5rem;
  }
}
.brands-collection__elements--urls {
  display: block;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  transition: background 0.3s ease-in-out;
}
.brands-collection__elements--urls:hover,
.brands-collection__elements--urls:focus {
  background: rgba(255, 255, 255, 0.9);
}
.brands-collection__elements--urls:focus {
  background: #fff;
}
.brands-collection__elements--urls:focus svg {
  fill: #050033;
}
.brands-collection__elements--urls:active {
  background: #050033;
}
.brands-collection__elements--logos {
  display: block;
  fill: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: fill 0.2s;
  width: 100%;
}
.brands-collection__elements--logos:hover {
  fill: #050033;
}
.brands-collection__elements--logos:focus {
  fill: #050033;
}
.brands-collection__elements--logos:active {
  background: #fff;
  fill: #050033;
}
.brands-collection__allBrands {
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  display: table;
  margin: 40px auto 20px;
  padding: 12px 32px;
  text-decoration: none;
  transition: background 0.2s, border 0.2s;
}
@media (min-width: 768px) {
  .brands-collection__allBrands {
    display: none;
  }
}
.brands-collection__allBrands:hover {
  background-color: #fff;
  color: #050033;
  text-decoration: none;
}
.footerComponent__wrapper .footerCopyright__top b,
.footerComponent__wrapper .footerLegalNotice__small b,
.footerComponent__wrapper .footerLinks .linksList__btn b,
.footerComponent__wrapper .footerLinks .linksList__li b,
.footerComponent__wrapper .footerLinks .linksList__noBtn b,
.footerComponent__wrapper .legalLinks__link b,
.footerComponent__wrapper section.footerNewsletter .newsLetter__input b,
body .footerComponent__title b {
  font-weight: 600;
}
.footerComponent__wrapper .footerCopyright__top i,
.footerComponent__wrapper .footerLegalNotice__small i,
.footerComponent__wrapper .footerLinks .linksList__btn i,
.footerComponent__wrapper .footerLinks .linksList__li i,
.footerComponent__wrapper .footerLinks .linksList__noBtn i,
.footerComponent__wrapper .legalLinks__link i,
.footerComponent__wrapper section.footerNewsletter .newsLetter__input i,
body .footerComponent__title i {
  font-style: italic;
}
[data-focus-tabbed] {
  outline: 0;
  position: relative;
}
[data-focus-tabbed]::after {
  background-color: transparent;
  border: 2px solid #3470df;
  border-radius: inherit;
  bottom: -1px;
  content: "";
  display: none;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -1px;
  z-index: 11;
}
[data-focus-tabbed]:focus-visible::after {
  content: "";
  display: block;
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
body .footerComponent {
  background: #f4f4f5;
  color: #252339;
  font-size: initial;
  margin-top: 56px;
  overflow: auto;
}
@media screen and (min-width: 768px) {
  body .footerComponent {
    margin-top: 80px;
  }
}
@media screen and (min-width: 1024px) {
  body .footerComponent {
    margin-top: 96px;
  }
}
body .footerComponent__wrapper {
  -ms-flex-align: baseline;
  align-items: baseline;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 16px;
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  body .footerComponent__wrapper {
    padding: 16px 32px 32px;
  }
}
@media screen and (min-width: 1024px) {
  body .footerComponent__wrapper {
    max-width: 1436px;
    padding: 16px 64px 64px;
  }
}
body .footerComponent__hr {
  border: 0;
  border-color: #fff;
  border-top: 1px solid #dadadd;
  clear: both;
  margin: 32px 0;
  opacity: 0.15;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  body .footerComponent__hr {
    max-width: 1308px;
  }
}
@media screen and (min-width: 768px) {
  body .footerComponent__hr--mobile {
    display: none;
  }
}
body .footerComponent__hr--main {
  opacity: 0.6;
}
body .footerComponent__hr--invisible {
  border: 0;
  margin: 24px 0;
}
@media screen and (max-width: 767px) {
  body .footerComponent__hr--invisible {
    margin: 16px 0;
  }
}
body .footerComponent__title {
  font-family: Roboto, Arial, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: initial;
  text-align: left;
  margin: 0;
}
body .footerComponent a,
body .footerComponent button {
  color: #050033;
  cursor: pointer;
  font-family: sans-serif;
}
body .footerComponent a,
body .footerComponent button,
body .footerComponent input[type=text] {
  text-decoration: none;
}
body .footerComponent a:focus,
body .footerComponent button:focus,
body .footerComponent input[type=text]:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #3470df;
  outline: 0;
}
body .footerComponent a.inverse:focus,
body .footerComponent button.inverse:focus,
body .footerComponent input[type=text].inverse:focus {
  box-shadow: 0 0 0 1px #050033, 0 0 0 3px #fff;
}
body .footerComponent input[type=radio] {
  background: #fff;
}
body .footerComponent ul {
  list-style: none;
  padding: 0;
}
body .footerComponent .footerSection {
  margin: 8px 0;
  width: 100%;
}
body .footerComponent .roundButton {
  background: #1e1852;
  border: 1px solid #1e1852;
  border-radius: 30px;
  color: #1e1852;
  display: block;
  padding: 13px 32px;
  position: relative;
  text-align: center;
  transition: background 0.2s, border 0.2s;
}
body .footerComponent .roundButton:hover {
  background: #38326c;
  border-color: #38326c;
}
body .footerComponent .roundButton:active {
  background: #544e85;
  border-color: #544e85;
}
body .footerComponent .roundButton:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #3470df;
  outline: 0;
}
body .footerComponent .roundButton--secondaryInv {
  background: 0;
  border-color: #1e1852;
}
body .footerComponent .roundButton--secondaryInv:hover {
  background: #1e1852;
  border-color: #1e1852;
  color: #f4f4f5;
}
body .footerComponent .roundButton--secondaryInv:active {
  background: #1e1852;
  border-color: #1e1852;
  color: #1e1852;
}
body .footerComponent .roundButton--secondaryInv:focus {
  box-shadow: 0 0 0 1px #050033, 0 0 0 3px #fff;
}
.footerComponent__wrapper .footerCopyright {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .footerCopyright__top {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerCopyright__top {
    text-align: center;
  }
}
.footerComponent__wrapper .footerCopyright__elmt {
  display: block;
}
.footerComponent__wrapper .footerCopyright__elmt:last-child {
  margin-top: 6px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerCopyright__elmt {
    display: inline-block;
    margin: 0 20px;
  }
}
.footerComponent__wrapper .footerLinks,
.footerComponent__wrapper .legalLinks {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .footerLinks .linksList {
  margin: 12px 0;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1280px) {
  .footerComponent__wrapper .footerLinks .linksList {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.footerComponent__wrapper .footerLinks .linksList__list {
  border-top: 1px solid #e5e5e5;
  padding: 12px 0;
}
.footerComponent__wrapper .footerLinks .linksList__list:first-child {
  border: 0;
  padding-top: 0;
}
.footerComponent__wrapper .footerLinks .linksList__list:last-child {
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList__list {
    border: 0;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    margin: 24px 0;
    padding: 0;
  }
}
@media screen and (min-width: 1024px) {
  .footerComponent__wrapper .footerLinks .linksList__list {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    margin: 0;
  }
}
@media screen and (min-width: 1280px) {
  .footerComponent__wrapper .footerLinks .linksList__list {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
  }
}
.footerComponent__wrapper .footerLinks .linksList__btn,
.footerComponent__wrapper .footerLinks .linksList__noBtn {
  background: 0;
  border: 0;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.footerComponent__wrapper .footerLinks .linksList__btn {
  display: block;
  color: inherit;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
}
.footerComponent__wrapper .footerLinks .linksList__btn::after,
.footerComponent__wrapper .footerLinks .linksList__btn::before {
  background: #050033;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  transition: transform 0.5s;
  width: 8px;
}
.footerComponent__wrapper .footerLinks .linksList__btn::before {
  right: 5px;
  transform: rotate(45deg);
}
.footerComponent__wrapper .footerLinks .linksList__btn::after {
  right: 0;
  transform: rotate(-45deg);
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList__btn {
    display: none;
  }
}
.footerComponent__wrapper .footerLinks .linksList__noBtn,
.footerComponent__wrapper section.helps.footerLinks--help .linksList__btn {
  display: none;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList__noBtn {
    display: inline;
  }
}
.footerComponent__wrapper .footerLinks .linksList__ul {
  display: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList__ul {
    display: block;
    max-height: none;
    overflow: visible;
  }
}
.footerComponent__wrapper .footerLinks .linksList__li {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 12px;
}
.footerComponent__wrapper section.helps {
  -ms-flex-preferred-size: calc(25% - 32px);
  flex-basis: calc(25% - 32px);
  margin: 0;
  padding: 32px 0 32px 32px;
}
@media screen and (max-width: 767px) {
  .footerComponent__wrapper section.helps {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footerComponent__wrapper section.helps {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .footerComponent__wrapper section.helps {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}
@media screen and (max-width: 1279px) {
  .footerComponent__wrapper section.helps {
    padding-left: 0;
  }
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper section.helps {
    -ms-flex-line-pack: start;
    align-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.footerComponent__wrapper section.helps.footerLinks--help .linksList__ul {
  margin-top: 30px;
}
.footerComponent__wrapper section.helps.footerLinks--help .linksList__li {
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .legalLinks__ul {
    text-align: center;
  }
}
.footerComponent__wrapper .legalLinks__li {
  margin-top: 6px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .legalLinks__li {
    display: inline-block;
    margin: 0 19px;
  }
  .footerComponent__wrapper .legalLinks__li:first-child {
    margin-left: 0;
  }
  .footerComponent__wrapper .legalLinks__li:last-child {
    margin-right: 0;
  }
}
.footerComponent__wrapper .legalLinks__link {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: block;
}
.footerComponent__wrapper .legalLinks__abbr[title] {
  text-decoration: none;
}
.footerComponent__wrapper .footerLegalLogos {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .footerLegalLogos__logos {
  text-align: center;
}
.footerComponent__wrapper .footerLegalLogos__logo {
  display: inline-block;
  vertical-align: middle;
}
.footerComponent__wrapper .footerLegalLogos__logo--fevad {
  margin-right: 17px;
}
.footerComponent__wrapper .footerLegalLogos__logo--secure {
  margin-left: 17px;
}
.footerComponent__wrapper .footerLegalNotice {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .footerLegalNotice__small {
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 12px;
  text-align: center;
}
.footerComponent__wrapper .logo {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #fff;
  overflow: auto;
  width: 100%;
}
.footerComponent__wrapper .logo .hidden {
  display: none;
}
.footerComponent__wrapper .logo__wrapper {
  margin: 0 auto;
  max-width: 1436px;
}
.footerComponent__wrapper .logo__hr {
  border: 0;
  border-top: 1px solid #fff;
  clear: both;
  margin: 40px 0 0;
  opacity: 0.6;
}
.footerComponent__wrapper .logo__hr:nth-child(1) {
  opacity: 0.6;
}
.footerComponent__wrapper .logo__svg {
  background-color: #050033;
  display: block;
  fill: #fff;
  height: 60px;
  margin: -30px auto 0;
  padding: 0 32px;
  position: relative;
  width: 264px;
}
.footerComponent__wrapper .loyalty {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .loyalty__button {
  margin: auto;
  width: -webkit-fit-content;
  width: fit-content;
}
.footerComponent__wrapper section.footerNewsletter {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  margin: 0;
}
@media screen and (max-width: 1279px) {
  .footerComponent__wrapper section.footerNewsletter {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .footerComponent__wrapper section.footerNewsletter .newsLetter {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}
@media screen and (min-width: 1280px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter {
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 32px;
    padding-right: 32px;
  }
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__text {
  margin-top: 10px;
  font-family: sans-serif;
}
@media screen and (min-width: 1024px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__text {
    margin-top: 30px;
  }
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__form {
    margin-top: 46px;
  }
}
@media screen and (min-width: 1024px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__form {
    margin-top: 20px;
  }
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__input {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  border: 0;
  border-radius: 6px;
  padding: 16px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__input {
    width: calc((100% + 24px)/8*4 - 24px);
    margin-right: 24px;
  }
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__button {
  margin: 30px auto 0;
  font-size: 100%;
}
.newsLetter__button:hover {
  background-color: #1e1852;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__button {
    margin: 0;
  }
  .footerComponent__wrapper section.footerNewsletter .footerComponent__hr {
    display: none;
  }
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__radio {
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  width: 100%;
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__radio .label {
  -ms-flex-align: baseline;
  align-items: baseline;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__radio .label__text {
  -ms-flex: 1;
  flex: 1;
  padding-left: 0.5em;
}
.footerComponent__wrapper .footerSharing {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .footerComponent__wrapper .footerSharing {
    display: block;
  }
}
@media screen and (max-width: 1023px) {
  .footerComponent__wrapper .footerSharing__ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.footerComponent__wrapper .footerSharing__li {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerSharing__li {
    display: inline-block;
    margin: 0 20px;
  }
}
.footerComponent__wrapper .footerSharing__li--weibo {
  display: none;
}
.footerComponent__wrapper .footerSharing__link {
  display: block;
  line-height: 0;
}
.footerComponent__wrapper .footerSharing__svg {
  fill: #1e1852;
  height: 28px;
  width: 28px;
}
.footerComponent__wrapper .footerSharing__tripadvisorDiv {
  margin: 25px auto 0;
  width: 120px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerSharing__tripadvisorDiv {
    display: inline-block;
    margin: 0 0 0 50px;
  }
}
.footerComponent__wrapper .footerSharing__tripadvisorSvg {
  height: 28px;
  width: 120px;
}
.brands-collection {
  color: #1e1852;
}
.brands-collection__elements--logos,
.footerComponent__wrapper .footerLegalLogos__logo--secure {
  fill: #1e1852;
}
.brands-collection__wrapper > .brands-collection__logo > svg {
  fill: #1e1852 !important;
  background-color: #f4f4f5;
  display: block;
  height: 80px;
  margin: -30px auto 45px;
  padding: 0;
  position: relative;
  max-width: 250px;
  width: 200px;
}
.footerComponent__wrapper section.callCenter {
  -ms-flex-preferred-size: calc(25% - 32px);
  flex-basis: calc(25% - 32px);
  margin: 0;
  padding: 32px 0 32px 32px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .footerComponent__wrapper section.callCenter {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footerComponent__wrapper section.callCenter {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .footerComponent__wrapper section.callCenter {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    padding: 0;
  }
}
@media screen and (max-width: 1023px) {
  .footerComponent__wrapper section.callCenter {
    padding-left: 0;
  }
}
.footerComponent__wrapper section.callCenter .callCenter__title {
  display: none;
  -ms-flex-order: 1;
  order: 1;
  width: 100%;
}
.footerComponent__wrapper section.callCenter .callCenter__title--limitless {
  display: block;
}
.footerComponent__wrapper section.callCenter .callCenter__ul {
  -ms-flex-order: 3;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  order: 3;
}
.footerComponent__wrapper section.callCenter .callCenter__ul:last-child {
  margin-top: 12px;
}
.footerComponent__wrapper section.callCenter .callCenter__li {
  margin-top: 12px;
}
.footerComponent__wrapper section.callCenter .callCenter__li:first-child {
  margin-top: 0;
}
.footerComponent__wrapper section.callCenter .callCenter__p {
  display: inline;
}
.footerComponent__wrapper section.callCenter .callCenter__after-stars {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  display: block;
  margin-top: 10px;
}
.china .footerComponent__wrapper .footerSharing__li,
.footerComponent__wrapper section.callCenter .legal {
  display: none;
}
.france .footerComponent__wrapper .callCenter.call-payed {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Arial;
  text-align: center;
}
.france .footerComponent__wrapper .callCenter.call-payed .callCenter__li {
  width: -webkit-fit-content;
  width: fit-content;
}
.france .footerComponent__wrapper .callCenter.call-payed .dial {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  color: #a50f78;
  font-weight: 700;
  padding: 5px;
  position: relative;
}
.france .footerComponent__wrapper .callCenter.call-payed .dial::before {
  background: #fff;
  bottom: -5px;
  content: "";
  display: block;
  height: 10px;
  left: 10px;
  position: absolute;
  right: -5px;
  transform: rotate(45deg);
  width: 10px;
}
.france .footerComponent__wrapper .callCenter.call-payed .dial a,
.france .footerComponent__wrapper .callCenter.call-payed .dial__stars {
  color: #a50f78;
}
.france .footerComponent__wrapper .callCenter.call-payed .legal {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  background: #a50f78;
  color: #fff;
  display: block;
  margin: 0;
  padding: 10px 5px 5px;
}
.france .footerComponent__wrapper .callCenter.free .dial {
  color: #91919b;
}
.france .footerComponent__wrapper .callCenter.free .legal {
  background: #91919b;
}
.china .footerComponent__wrapper .footerSharing__li--weibo {
  display: block;
}
@media screen and (min-width: 768px) {
  .china .footerComponent__wrapper .footerSharing__li--weibo {
    display: inline-block;
  }
}
[dir=rtl] .footerComponent__wrapper .callCenter .dial {
  max-width: 200px;
}
.brands-collection__allBrands {
  color: #050505;
  border: 1px solid #050033;
  background: 0 0;
}
.brands-collection__allBrands:hover {
  color: #fff;
  background: #050033;
}
.brands-collection__hr--main {
  border: 1px solid #dadadd;
}
#tripadvisor path:nth-child(6),
#tripadvisor path:nth-child(7) {
  fill: #000;
}
.footerComponent__wrapper .footerLinks .linksList__ul.open {
  max-height: 100%;
  overflow: visible;
}
.dial {
  font-family: "Roboto", Arial, sans-serif;
}
.brands-collection__elements--logos:hover {
  fill: #f4f4f5;
}
.brands-collection__elements--urls:hover {
  background-color: #1e1852;
}
.hidden {
  display: none;
}
/* -- VARIABLES -- */
/* Devices Breakpoints normals*/
.toolbox__container {
  display: none;
  position: fixed;
  right: 0px;
  top: 150px;
  z-index: 1000;
}
@media (min-width: 1024px) {
  .toolbox__container {
    display: -ms-flexbox;
    display: flex;
  }
}
.toolbox__toggle {
  background-color: #fff;
  border: 1px solid rgba(156, 172, 186, 0.5);
  border-radius: 5px 0px 0px 5px;
  border-right: 0px;
  height: 32px;
  position: absolute;
  right: 0px;
  width: 32px;
}
.toolbox__toggle:hover {
  cursor: pointer;
}
.toolbox__content {
  display: none;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  margin-top: 32px;
  max-height: 320px;
  max-width: 165px;
  overflow: hidden;
  transition: max-height 0.15s linear, max-width 0.15s linear 0.15s;
}
.toolbox__content--active {
  display: block;
}
.toolbox__resume {
  display: none;
  height: 0px;
  margin: 30px -32px 0px 0px;
  width: 100px;
}
.toolbox__resume--active {
  display: block;
}
.toolbox__resume_text {
  background-color: #fff;
  border: 1px solid rgba(156, 172, 186, 0.5);
  border-radius: 0px 0px 5px 5px;
  border-top: 0px;
  cursor: pointer;
  display: block;
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(90deg);
}
.toolbox__list {
  list-style-type: none;
  padding: 0px;
}
.toolbox__item {
  -ms-flex-align: center;
      align-items: center;
  background-color: #fff;
  border: 1px solid rgba(156, 172, 186, 0.5);
  border-radius: 4px 0px 0px 4px;
  border-right: 0px;
  color: #1E1852;
  display: -ms-flexbox;
  display: flex;
  left: 120px;
  margin: 3px auto;
  min-width: 165px;
  padding: 6px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s linear;
}
.toolbox__item:hover,
.toolbox__item:focus {
  left: 0px;
}
.toolbox__item:focus-visible {
  left: 0px;
}
.toolbox__label {
  font-size: 11px;
  margin: 0px;
}
.toolbox__icon {
  background-size: cover;
  display: block;
  height: 32px;
  margin-right: 10px;
  width: 32px;
}
.toolbox__icon--close {
  height: 16px;
  margin: 7px auto;
  width: 16px;
}
/*
Icon classes can be used entirely standalone. They are named after their original file names.

*/
.ico {
  background: url('../assets/images/common/spritesheet-meeting.png') no-repeat;
  display: block;
}
.ico-top-destination {
  background-position: -50px -1150px;
  min-height: 44px;
  padding-left: 38px;
}
.ico-all-accor {
  background-position: -44px -94px;
  height: 31px;
  width: 43px;
}
.ico-all-booking {
  background-position: 0 -94px;
  display: block;
  height: 600px;
  width: 238px;
}
.ico-arrow-tb-closed {
  background-position: -80px -168px;
  display: block;
  height: 23px;
  width: 23px;
}
.ico-arrow-tb-opened {
  background-position: -103px -168px;
  display: block;
  height: 23px;
  width: 23px;
}
.ico-arrowdl-grey {
  background-position: -206px -151px;
  display: block;
  height: 8px;
  width: 9px;
}
.ico-arrowdl-white {
  background-position: -131px -112px;
  display: block;
  height: 8px;
  width: 8px;
}
.ico-best-prices {
  background-position: -54px 0;
  display: block;
  height: 49px;
  width: 49px;
}
.ico-brands {
  background-position: -181px -24px;
  display: block;
  height: 26px;
  margin: auto 32px;
  position: absolute;
  width: 26px;
}
.ico-burger {
  background-position: no-repeat -181px -82px;
  display: block;
  font-size: 16px;
  height: 21px;
  padding-left: 25px;
  position: absolute;
  width: 20px;
}
.ico-byadresse-white {
  background-position: -105px -81px;
  height: 11px;
  width: 13px;
}
.ico-byadresse {
  background-position: -120px -81px;
  height: 11px;
  width: 13px;
}
.ico-bydestination-white {
  background-position: -93px -54px;
  height: 14px;
  width: 10px;
}
.ico-bydestination {
  background-position: -93px -70px;
  height: 14px;
  width: 10px;
}
.ico-calendar {
  background-position: -217px -174px;
  height: 19px;
  width: 16px;
}
.ico-card {
  background-position: -24px -172px;
  height: 15px;
  width: 23px;
}
.ico-contracted {
  background-position: -181px -141px;
  height: 21px;
  width: 23px;
}
.ico-customise {
  background-position: -105px 0;
  height: 39px;
  width: 42px;
}
.ico-findyourhotel {
  background-position: -217px 0;
  height: 22px;
  width: 21px;
}
.ico-global-quote {
  background-position: -33px -130px;
  height: 38px;
  width: 31px;
}
.ico-help {
  background-position: -217px -71px;
  height: 20px;
  width: 20px;
}
.ico-lightbulb {
  background-position: -149px -45px;
  display: block;
  height: 32px;
  margin: auto -12px;
  position: absolute;
  width: 30px;
}
.ico-loupe-white {
  background-position: 0 -195px;
  display: block;
  height: 17px;
  width: 17px;
}
.ico-loyalty {
  background-position: -181px -124px;
  display: block;
  height: 15px;
  width: 32px;
}
.ico-media-center {
  background-position: 0 -54px;
  display: block;
  height: 38px;
  width: 51px;
}
.ico-newsletter {
  background-position: 1px 2px;
  height: 55px;
  width: 55px;
}
.ico-onamap-white {
  background-position: -164px -114px;
  height: 12px;
  width: 13px;
}
.ico-onamap {
  background-position: -49px -172px;
  height: 12px;
  width: 13px;
}
.ico-onlinebooking {
  background-position: -149px 0;
  height: 43px;
  width: 30px;
}
.ico-othersite {
  background-position: -131px -94px;
  height: 16px;
  width: 16px;
}
.ico-ourselection {
  background-position: -181px 0;
  height: 22px;
  width: 34px;
}
.ico-real-time {
  background-position: 0 -130px;
  height: 40px;
  width: 31px;
}
.ico-searchbook {
  background-position: -19px -195px;
  height: 17px;
  width: 13px;
}
.ico-services {
  background-position: -217px -156px;
  height: 16px;
  width: 19px;
}
.ico-slide-left {
  background-position: -105px -41px;
  height: 38px;
  width: 38px;
}
.ico-slide-right {
  background-position: -53px -54px;
  height: 38px;
  width: 38px;
}
.ico-specialdeals {
  background-position: -217px -49px;
  height: 20px;
  width: 21px;
}
.ico-events {
  background-position: -188px -540px;
  height: 26px;
  margin: auto -31px;
  position: absolute;
  width: 26px;
}
.ico-square-arrow {
  background-position: -149px -114px;
  height: 13px;
  width: 13px;
}
.ico-star {
  background-position: -217px -136px;
  height: 18px;
  width: 19px;
}
.ico-tb-app {
  background-position: -149px -79px;
  height: 33px;
  width: 28px;
}
.ico-tb-calculator {
  background-position: -135px -130px;
  height: 22px;
  width: 38px;
}
.ico-tb-contact {
  background-position: -181px -104px;
  height: 18px;
  width: 28px;
}
.ico-tb-converter {
  background-position: -89px -94px;
  height: 29px;
  width: 40px;
}
.ico-tb-faq {
  background-position: -66px -130px;
  height: 30px;
  width: 30px;
}
.ico-tb-manage {
  background-position: -217px -24px;
  height: 23px;
  width: 20px;
}
.ico-tb-photo {
  background-position: -98px -130px;
  height: 25px;
  width: 35px;
}
.ico-toolbox {
  background-position: -217px -115px;
  height: 19px;
  width: 19px;
}
.ico-whybook {
  background-position: 0 -172px;
  height: 21px;
  width: 22px;
}
.ico-yourneeds {
  background-position: -181px -52px;
  height: 25px;
  width: 27px;
}
.ico-arrow-backtotop {
  background-position: no-repeat 14px 15px;
  height: 40px;
  width: 40px;
}
.ico-customised-services {
  background-position: -163px -342px;
  height: 61px;
  width: 76px;
}
.ico-spaces {
  background-position: -1px -332px;
  height: 60px;
  width: 48px;
}
.ico-catering {
  background-position: -1px -461px;
  height: 60px;
  width: 75px;
}
.ico-strategic-locations {
  background-position: -1px -396px;
  height: 60px;
  width: 67px;
}
.ico-incentive-breaks {
  background-position: -149px -466px;
  height: 60px;
  width: 65px;
}
.ico .item-successful-event {
  background-position: -3px -534px;
  height: 60px;
  width: 49px;
}
.ico-unique-locations {
  background-position: -115px -341px;
  height: 60px;
  width: 42px;
}
.ico-our-venues {
  background-position: -84px -464px;
  height: 60px;
  width: 57px;
}
.ico-offers-and-spaces {
  background-position: -78px -409px;
  height: 47px;
  width: 100px;
}
.ico-became-member {
  background-position: 1px -261px;
  height: 54px;
  width: 42px;
}
.ico-login-account {
  background-position: -46px -257px;
  height: 54px;
  width: 27px;
}
.ico-mediatheque {
  background-position: -167px -296px;
  height: 23px;
  width: 31px;
}
.ico-phototheque {
  background-position: -199px -295px;
  height: 44px;
  width: 39px;
}
.ico-faq {
  background-position: -133px -297px;
  height: 29px;
  width: 30px;
}
.ico-enveloppe {
  background-position: -88px -302px;
  height: 24px;
  width: 37px;
}
.ico {
  background-image: url('/assets/images/common/common-sprite.png') no-repeat;
}
.ico .icon-advantage {
  background-position: -217px -93px;
  display: block;
  height: 20px;
  width: 20px;
}
.ico -resort {
  background-position: 0 -1350px;
  display: block;
  height: 26px;
  width: 27px;
}
.ico -resort:hover {
  background-position: 0 -1376px;
}
.ico -resort-suite {
  background-position: -27px -1350px;
  display: block;
  height: 26px;
  width: 27px;
}
.ico -resort-suite:hover {
  background-position: -27px -1376px;
}
.ico -resort-golf {
  background-position: -54px -1350px;
  display: block;
  height: 26px;
  width: 27px;
}
.ico -resort-golf:hover {
  background-position: -54px -1376px;
}
.ico -resort-shopping {
  background-position: -81px -1350px;
  display: block;
  height: 26px;
  width: 27px;
}
.ico -resort-shopping:hover {
  background-position: -81px -1376px;
}
.ico -resort-airport {
  background-position: -108px -1350px;
  display: block;
  height: 26px;
  width: 27px;
}
.ico -resort-airport:hover {
  background-position: -108px -1376px;
}
.ico -resort-island {
  background-position: -135px -1350px;
  display: block;
  height: 26px;
  width: 27px;
}
.ico -resort-island:hover {
  background-position: -135px -1376px;
}
.ico -resort-ski {
  background-position: -162px -1350px;
  display: block;
  height: 26px;
  width: 27px;
}
.ico -resort-ski:hover {
  background-position: -162px -1376px;
}
/* -- VARIABLES -- */
.back-top {
  bottom: 1%;
  display: none;
  position: fixed;
  right: 2%;
}
@media (min-width: 768px) {
  .back-top {
    display: block;
  }
}
.back-top__link {
  bottom: 0;
  line-height: 0.688rem;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
}
.back-top__icon {
  background-color: #050033;
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  display: inline-block;
  font-size: 1.25rem;
  height: 40px;
  line-height: 32px;
  margin-bottom: 6px;
  width: 40px;
}
.back-top__icon::before {
  content: '↑';
}
.back-top__text {
  color: grey;
  display: block;
  font-size: 0.563rem;
  font-weight: bold;
  text-transform: uppercase;
}

/*# sourceMappingURL=to.css.map */