// header SF prerendering
#ah-header-wrapper {
  display: none;
}
#mount-ah-navigation {
  min-height: 4.5rem;
}
@media (min-width: @tablet) {
  #loginnav {
    display: none;
  }
}
