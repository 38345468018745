/*
Icon classes can be used entirely standalone. They are named after their original file names.

*/

.ico {

  background: url('../assets/images/common/spritesheet-meeting.png') no-repeat;
  display: block;

  &-top-destination {
    background-position: -50px -1150px;
    min-height: 44px;
    padding-left: 38px;
  }

  &-all-accor {
    background-position: -44px -94px;
    height: 31px;
    width: 43px;
  }

  &-all-booking {
    background-position: 0 -94px;
    display: block;
    height: 600px;
    width: 238px;
  }

  &-arrow-tb-closed {
    background-position: -80px -168px;
    display: block;
    height: 23px;
    width: 23px;
  }

  &-arrow-tb-opened {
    background-position: -103px -168px;
    display: block;
    height: 23px;
    width: 23px;
  }

  &-arrowdl-grey {
    background-position: -206px -151px;
    display: block;
    height: 8px;
    width: 9px;
  }

  &-arrowdl-white {
    background-position: -131px -112px;
    display: block;
    height: 8px;
    width: 8px;
  }

  &-best-prices {
    background-position: -54px 0;
    display: block;
    height: 49px;
    width: 49px;
  }

  &-brands {
    background-position: -181px -24px;
    display: block;
    height: 26px;
    margin: auto 32px;
    position: absolute;
    width: 26px;
  }

  &-burger {
    background-position: no-repeat -181px -82px;
    display: block;
    font-size: 16px;
    height: 21px;
    padding-left: 25px;
    position: absolute;
    width: 20px;
  }

  &-byadresse-white {
    background-position: -105px -81px;
    height: 11px;
    width: 13px;
  }

  &-byadresse {
    background-position: -120px -81px;
    height: 11px;
    width: 13px;
  }

  &-bydestination-white {
    background-position: -93px -54px;
    height: 14px;
    width: 10px;
  }

  &-bydestination {
    background-position: -93px -70px;
    height: 14px;
    width: 10px;
  }

  &-calendar {
    background-position: -217px -174px;
    height: 19px;
    width: 16px;
  }

  &-card {
    background-position: -24px -172px;
    height: 15px;
    width: 23px;
  }

  &-contracted {
    background-position: -181px -141px;
    height: 21px;
    width: 23px;
  }

  &-customise {
    background-position: -105px 0;
    height: 39px;
    width: 42px;
  }

  &-findyourhotel {
    background-position: -217px 0;
    height: 22px;
    width: 21px;
  }

  &-global-quote {
    background-position: -33px -130px;
    height: 38px;
    width: 31px;
  }

  &-help {
    background-position: -217px -71px;
    height: 20px;
    width: 20px;
  }

  &-lightbulb {
    background-position: -149px -45px;
    display: block;
    height: 32px;
    margin: auto -12px;
    position: absolute;
    width: 30px;
  }

  &-loupe-white {
    background-position: 0 -195px;
    display: block;
    height: 17px;
    width: 17px;
  }

  &-loyalty {
    background-position: -181px -124px;
    display: block;
    height: 15px;
    width: 32px;
  }

  &-media-center {
    background-position: 0 -54px;
    display: block;
    height: 38px;
    width: 51px;
  }

  &-newsletter {
    background-position: 1px 2px;
    height: 55px;
    width: 55px;
  }

  &-onamap-white {
    background-position: -164px -114px;
    height: 12px;
    width: 13px;
  }

  &-onamap {
    background-position: -49px -172px;
    height: 12px;
    width: 13px;
  }

  &-onlinebooking {
    background-position: -149px 0;
    height: 43px;
    width: 30px;
  }

  &-othersite {
    background-position: -131px -94px;
    height: 16px;
    width: 16px;
  }

  &-ourselection {
    background-position: -181px 0;
    height: 22px;
    width: 34px;
  }

  &-real-time {
    background-position: 0 -130px;
    height: 40px;
    width: 31px;
  }

  &-searchbook {
    background-position: -19px -195px;
    height: 17px;
    width: 13px;
  }

  &-services {
    background-position: -217px -156px;
    height: 16px;
    width: 19px;
  }

  &-slide-left {
    background-position: -105px -41px;
    height: 38px;
    width: 38px;
  }

  &-slide-right {
    background-position: -53px -54px;
    height: 38px;
    width: 38px;
  }

  &-specialdeals {
    background-position: -217px -49px;
    height: 20px;
    width: 21px;
  }

  &-events {
    background-position: -188px -540px;
    height: 26px;
    margin: auto -31px;
    position: absolute;
    width: 26px;
  }

  &-square-arrow {
    background-position: -149px -114px;
    height: 13px;
    width: 13px;
  }

  &-star {
    background-position: -217px -136px;
    height: 18px;
    width: 19px;
  }

  &-tb-app {
    background-position: -149px -79px;
    height: 33px;
    width: 28px;
  }

  &-tb-calculator {
    background-position: -135px -130px;
    height: 22px;
    width: 38px;
  }

  &-tb-contact {
    background-position: -181px -104px;
    height: 18px;
    width: 28px;
  }

  &-tb-converter {
    background-position: -89px -94px;
    height: 29px;
    width: 40px;
  }

  &-tb-faq {
    background-position: -66px -130px;
    height: 30px;
    width: 30px;
  }

  &-tb-manage {
    background-position: -217px -24px;
    height: 23px;
    width: 20px;
  }

  &-tb-photo {
    background-position: -98px -130px;
    height: 25px;
    width: 35px;
  }

  &-toolbox {
    background-position: -217px -115px;
    height: 19px;
    width: 19px;
  }

  &-whybook {
    background-position: 0 -172px;
    height: 21px;
    width: 22px;
  }

  &-yourneeds {
    background-position: -181px -52px;
    height: 25px;
    width: 27px;
  }

  &-arrow-backtotop {
    background-position: no-repeat 14px 15px;
    height: 40px;
    width: 40px;
  }

  &-customised-services {
    background-position: -163px -342px;
    height: 61px;
    width: 76px;
  }

  &-spaces {
    background-position: -1px -332px;
    height: 60px;
    width: 48px;
  }

  &-catering {
    background-position: -1px -461px;
    height: 60px;
    width: 75px;
  }

  &-strategic-locations {
    background-position: -1px -396px;
    height: 60px;
    width: 67px;
  }

  &-incentive-breaks {
    background-position: -149px -466px;
    height: 60px;
    width: 65px;
  }

  .item-successful-event {
    background-position: -3px -534px;
    height: 60px;
    width: 49px;
  }

  &-unique-locations {
    background-position: -115px -341px;
    height: 60px;
    width: 42px;

  }

  &-our-venues {
    background-position: -84px -464px;
    height: 60px;
    width: 57px;

  }

  &-offers-and-spaces {
    background-position: -78px -409px;
    height: 47px;
    width: 100px;
  }

  &-became-member {
    background-position: 1px -261px;
    height: 54px;
    width: 42px;
  }

  &-login-account {
    background-position: -46px -257px;
    height: 54px;
    width: 27px;
  }

  &-mediatheque {
    background-position: -167px -296px;
    height: 23px;
    width: 31px;
  }

  &-phototheque {
    background-position: -199px -295px;
    height: 44px;
    width: 39px;
  }

  &-faq {
    background-position: -133px -297px;
    height: 29px;
    width: 30px;
  }

  &-enveloppe {
    background-position: -88px -302px;
    height: 24px;
    width: 37px;
  }

}

.ico {
  background-image: url('/assets/images/common/common-sprite.png') no-repeat;


  .icon-advantage {
    background-position: -217px -93px;
    display: block;
    height: 20px;
    width: 20px;
  }


  & -resort {
    background-position: 0 -1350px;
    display: block;
    height: 26px;
    width: 27px;

    &:hover {
      background-position: 0 -1376px;
    }

    &-suite {
      background-position: -27px -1350px;
      display: block;
      height: 26px;
      width: 27px;

      &:hover {
        background-position: -27px -1376px;
      }
    }

    &-golf {
      background-position: -54px -1350px;
      display: block;
      height: 26px;
      width: 27px;

      &:hover {
        background-position: -54px -1376px;
      }
    }

    &-shopping {
      background-position: -81px -1350px;
      display: block;
      height: 26px;
      width: 27px;

      &:hover {
        background-position: -81px -1376px;
      }
    }

    &-airport {
      background-position: -108px -1350px;
      display: block;
      height: 26px;
      width: 27px;

      &:hover {
        background-position: -108px -1376px;
      }
    }

    &-island {
      background-position: -135px -1350px;
      display: block;
      height: 26px;
      width: 27px;

      &:hover {
        background-position: -135px -1376px;
      }
    }

    &-ski {
      background-position: -162px -1350px;
      display: block;
      height: 26px;
      width: 27px;

      &:hover {
        background-position: -162px -1376px;
      }
    }
  }
}
