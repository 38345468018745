.font(@sizeValue: 16, @heightValue: 16) {
  @fontSizePx: ~'@{sizeValue}px';
  @fontSizeCalc: (0.1 * @sizeValue);
  @fontSizeRem: ~'@{fontSizeCalc}rem';
  @heightPx: ~'@{heightValue}px';
  @heightCalc: (0.1 * @heightValue);
  @heightRem: ~'@{heightCalc}rem';
  font-size: @fontSizeRem;
  line-height: @heightRem;
}

.font-16(@sizeValue: 16, @heightValue: 16) {
  @fontSizePx: ~'@{sizeValue}px';
  @fontSizeCalc: (0.1 * @sizeValue);
  @fontSizeRem: ~'@{fontSizeCalc}rem';
  @heightPx: ~'@{heightValue}px';
  @heightCalc: (0.1 * @heightValue);
  @heightRem: ~'@{heightCalc}rem';
  font-size: @fontSizeRem;
  line-height: @heightRem;
}

.border-radius(@radius) {
  -moz-border-radius: @radius;
  -webkit-border-radius: @radius;
  background-clip: padding-box;
  border-radius: @radius;

}

.opacity(@opacity) {
  filter: ~'alpha(opacity=@{opacity})';
  opacity: ~'@{opacityCalc}';
  @opacityCalc: (@opacity / 100);
}

.transform(@string) {
  -moz-transform: @string;
  -ms-transform: @string;
  -o-transform: @string;
  -webkit-transform: @string;
  transform: @string;
}

.box-shadow (@string) {
  -moz-box-shadow: @string;
  -webkit-box-shadow: @string;
  box-shadow: @string;
}

.colWidth(@nb, @total : 12) {
  width: calc((100% + @gutter-size) / @total * @nb - @gutter-size);
}
